import store from "@/store"
import tr from '../../dataTable/tr.json'
import en from '../../dataTable/en-gb.json'

export function dtLang(){
  switch (store.getters.getLocale) {
    case 'tr-TR':
      return tr
    default:
      return en
  }
}