<template>
  <b-row>
    <b-overlay :show="!tableReady" variant="dark" opacity="0.85" blur="2px" rounded="sm" class="w-100">
      <div v-if="paymentLinks">
        <table id="paymentLinksTable" class="dataTable table w-100" v-if="paymentLinks.length">
          <thead>
            <tr>
              <th>
                {{ $t("payment_link.title") }}
              </th>
              <th class="text-right">
                {{ $t("payment_link.amount") }}
              </th>
              <th>
                {{ $t("payment_link.created_by") }}
              </th>
              <th>
                {{ $t("payment_link.expired_date") }}
              </th>
              <th class="text-right">
                {{ $t("payment_link.limit") }}
              </th>
              <th class="text-center">
                {{ $t("payment_link.state") }}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in paymentLinks" :key="item.hash + '_' + index">
              <td class="limited-text">
                <p>
                  {{ item.title }}
                </p>
              </td>
              <td class="text-right text-nowrap" :data-order="item.amount">
                {{ showAmount(item.amount) }}
              </td>
              <td class="limited-text" :data-order="item.user.user_name_surname">
                <p>
                  {{ item.user.user_name_surname }}
                </p>
                <p>
                  {{ item.company.company_name }}
                </p>
              </td>
              <td class="text-nowrap" :data-order="tableDateOrderVal(item.create_date)">
                <div class="d-flex">
                  <p class="date-text">
                    {{ showDate(item.expired_date) }}
                  </p>
                  <span v-b-tooltip.hover.v-primary.top.html :title="expireDateTooltip(item)">
                    <feather-icon class="text-primary" icon="InfoIcon" size="25" />
                  </span>
                </div>
              </td>
              <td class="text-right">
                {{ item.limit }}
              </td>
              <td class="text-center">
                <b-badge :variant="linkState(item) ? 'light-success' : 'light-danger'">
                  {{ $t("payment_link.state_" + linkState(item)) }}
                </b-badge>
              </td>
              <td>
                <div class="process-div" v-if="linkState(item)">
                  <feather-icon icon="SendIcon" size="25" class=" text-info cursor-pointer" @click="openSendLinkModal(item)" :title="$t('payment_link.send_link')" v-b-tooltip.hover.v-info />
                  <b-button variant="flat-secondary" class="btn-icon" @click="copyLink(item, $event)" :title="$t('payment_link.copy')" v-b-tooltip.hover.v-secondary>
                    <feather-icon icon="CopyIcon" size="25" class="text-secondary cursor-pointer process-icon" />
                  </b-button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <b-alert v-else>{{ $t("payment_link.not_found") }}</b-alert>
      </div>
      <b-alert v-else>{{ $t("payment_link.not_found") }}</b-alert>
    </b-overlay>
    <b-modal ref="sendLinkModal" hide-footer centered no-close-on-esc no-close-on-backdrop size="lg" :title="$t('payment_link.send_modal.title')">
      <validation-observer ref="sendLinkValidation" v-slot="{ invalid }">
        <b-row class="send-row">
          <b-col cols="12" lg="6" class="send-column">
            <validation-provider #default="{ errors }" :name="$t('payment_link.send_modal.email')" rules="email">
              <b-form-group :label="$t('payment_link.send_modal.email')" label-for="payment-link-send-email">
                <b-input-group :class="{ 'is-invalid': errors.length > 0 }">
                  <b-form-input id="payment-link-send-email" v-model="email" trim :class="{ 'is-invalid': errors.length > 0 }" @keypress.enter="addEmail()" />
                  <b-input-group-append is-text>
                    <b-button variant="flat-primary" class="btn-icon p-0" :disabled="errors.length > 0 || !email || email === ''" @click="addEmail()">
                      <feather-icon icon="PlusIcon" size="20" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-card-text class="text-center">{{ $t("payment_link.send_modal.email_info") }}</b-card-text>
            <ul class="mt-1">
              <li v-for="(item, index) in emails" :key="'mail_' + item.email + '_' + index">
                <div class="d-flex justify-content-between align-items-center">
                  <span>{{ item.email }}</span>
                  <feather-icon icon="TrashIcon" size="20" class="text-danger cursor-pointer" @click="removeEmail(item.email)" />
                </div>
              </li>
            </ul>
          </b-col>
          <b-col cols="12" lg="6" class="send-column">
            <validation-provider #default="{ errors }" :name="$t('payment_link.send_modal.phone')" rules="min:10|max:10">
              <b-form-group :label="$t('payment_link.send_modal.phone')" label-for="payment-link-send-phone">
                <b-input-group :class="{ 'is-invalid': errors.length > 0 }">
                  <cleave id="payment-link-send-phone" v-model="phone" :options="phoneOptions" class="form-control" :class="{ 'is-invalid': errors.length > 0 }" @keypress.enter.native="addPhone()" />
                  <b-input-group-append is-text>
                    <b-button variant="flat-primary" class="btn-icon p-0" :disabled="errors.length > 0 || !phone || phone === ''" @click="addPhone()">
                      <feather-icon icon="PlusIcon" size="20" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-card-text class="text-center">{{ $t("payment_link.send_modal.phone_info") }}</b-card-text>
            <ul class="mt-1">
              <li v-for="(item, index) in phones" :key="'phone_' + item.number + '_' + index">
                <div class="d-flex justify-content-between align-items-center">
                  <span>{{ showPhone(item.number) }}</span>
                  <feather-icon icon="TrashIcon" size="20" class="text-danger cursor-pointer" @click="removePhone(item.number)" />
                </div>
              </li>
            </ul>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="d-flex justify-content-end align-items-center">
            <div>
              <b-button variant="success" :disabled="invalid || emails.length + phones.length === 0" @click="sendLink()">
                {{ $t("payment_link.send_modal.send") }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
  </b-row>
</template>

<style lang="scss" scoped>
@import "~@core/scss/base/core/colors/palette-variables.scss";
@import "~@core/scss/base/components/variables-dark";
.limited-text {
  max-width: 250px;
  p {
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.process-div {
  display: flex;
  gap: 1rem;
  justify-content: space-around;
  align-items: center;
  .process-icon {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
}

.send-row {
  div {
    ul {
      height: 300px;
      overflow-y: scroll;
      overflow-x: hidden;
      li {
        margin: 1rem 0;
      }
    }
  }
  .send-column:nth-child(1) {
    border-right: 1px solid $border-color;
  }
}
.dark-layout {
  .send-row {
    .send-column:nth-child(1) {
      border-color: $theme-dark-border-color !important;
    }
  }
}

.date-text {
  width: 9rem;
  margin: 0;
  line-height: 25px;
}
</style>

<script>
import { BRow, BCol, BAlert, BBadge, BButton, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BFormInvalidFeedback, BCardText, BOverlay, VBTooltip } from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate/dist/vee-validate.full";
import Cleave from "vue-cleave-component";
import "cleave.js/dist/addons/cleave-phone.tr.js";
import { GET_ALL_PAYMENT_LINKS, SEND_PAYMENT_LINK } from "@/store/services/paymentLink-service";
import "@/assets/js/functions/datatables.settings";
import { dtLang } from "@/assets/js/functions/dataTableLang";
import $ from "jquery";
import { mapGetters } from "vuex";
import { priceFormat } from "@/assets/js/functions/currency-functions";
import moment from "moment";
import { SET_NOTIFICATION } from "@/store/services/module-service";

export default {
  name: "payment-links-table",
  components: {
    BRow,
    BCol,
    BAlert,
    BBadge,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormInvalidFeedback,
    BCardText,
    BOverlay,
    Cleave,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      table: undefined,
      tableReady: false,
      email: undefined,
      phone: undefined,
      payment_link: undefined,
      emails: [],
      phones: [],
      phoneOptions: {
        phone: true,
        phoneRegionCode: "TR",
      },
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    ...mapGetters(["paymentLinks", "getLocale"]),
  },
  methods: {
    getData() {
      this.$store.dispatch(GET_ALL_PAYMENT_LINKS);
    },
    createTable() {
      this.tableReady = false;
      this.table = $("#paymentLinksTable").DataTable({
        columnDefs: [
          {
            orderable: false,
            targets: [6],
          },
          {
            type: "num",
            targets: [1, 3],
          },
        ],
        scrollX: true,
        scrollY: "1000px",
        scrollCollapse: true,
        order: [3, "desc"],
        buttons: [
          {
            extend: "colvis",
            text: this.$t("dataTable.show_hide"),
          },
          {
            extend: "pdf",
          },
          {
            extend: "excel",
            title: this.$t("payment_link.links"),
            filename: this.$t("payment_link.links"),
          },
        ],
        language: dtLang(),
      });
      this.tableReady = true;
    },
    refreshTable() {
      if (this.table !== undefined) {
        this.table.destroy();
      }
      this.$nextTick(() => {
        this.createTable();
      });
    },
    showAmount(amount) {
      return priceFormat(amount, this.getLocale, "TRY");
    },
    showDate(date) {
      return new Intl.DateTimeFormat(this.getLocale, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      }).format(new Date(date));
    },
    showPhone(phone) {
      return `+90 ${phone.slice(0, 3)} ${phone.slice(3, 6)} 
      ${phone.slice(6, 8)} ${phone.slice(8, 10)}`;
    },
    linkState(link) {
      if (link.limit === 0) {
        return false;
      } else {
        let today = moment();
        let expire = moment(link.expired_date);
        if (expire.diff(today, "days") >= 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    copyLink(item, event) {
      let url = this.$router.resolve({
        name: "PayWithToken",
        params: {
          hash: item.hash,
        },
      });

      this.$copyText(`${window.location.origin}${url.href}`)
        .then(() => {
          let elem = event.target.closest("svg");
          elem.classList.toggle("text-success");
          setTimeout(() => {
            elem.classList.toggle("text-success");
          }, 750);
          this.onCopy();
        })
        .catch(() => {
          this.onCopyError();
        });
    },
    onCopy() {
      let notification = {
        show: true,
        type: 1,
        title: this.$t("payment_link.link"),
        message: this.$t("payment_link.copy_success"),
        icon: "CheckCircleIcon",
        variant: "success",
      };
      this.$store.commit(SET_NOTIFICATION, notification);
    },
    onCopyError() {
      let notification = {
        show: true,
        type: 2,
        title: this.$t("payment_link.link"),
        message: this.$t("payment_link.copy_error"),
        icon: "error",
      };
      this.$store.commit(SET_NOTIFICATION, notification);
    },
    addEmail() {
      if (this.email && this.email !== "") {
        this.emails.push({
          email: this.email,
        });
        this.email = undefined;
      }
    },
    addPhone() {
      if (this.phone && this.phone !== "") {
        this.phones.push({
          number: this.phone,
        });
        this.phone = undefined;
      }
    },
    removeEmail(email) {
      let i = this.emails.findIndex((x) => x.email === email);
      this.emails.splice(i, 1);
    },
    removePhone(phone) {
      let i = this.phones.findIndex((x) => x.number === phone);
      this.phones.splice(i, 1);
    },
    openSendLinkModal(item) {
      this.payment_link = {
        ...item,
      };
      this.emails = [];
      this.phones = [];
      this.$refs.sendLinkModal.show();
    },
    sendLink() {
      let url = this.$router.resolve({
        name: "PayWithToken",
        params: {
          hash: "1",
        },
      });
      url = url.href.replace("1", "");

      let body = {
        payment_link_m_id: this.payment_link.payment_link_m_id,
        numbers: this.phones,
        emails: this.emails,
        payment_url: `${window.location.origin}${url}`,
      };

      this.$store.dispatch(SEND_PAYMENT_LINK, body).then(() => {
        this.$refs.sendLinkModal.hide();
      });
    },
    tableDateOrderVal(date) {
      return moment(date).format("X") * 1;
    },
    expireDateTooltip(item) {
      return `
      ${this.$t("payment_link.create_date")}
      <br/>
      ${this.showDate(item.create_date)}
      `;
    },
  },
  watch: {
    getLocale() {
      this.refreshTable();
    },
    paymentLinks() {
      this.refreshTable();
    },
  },
};
</script>
