<template>
  <validation-observer ref="paymentLinkValidation" v-slot="{ invalid }" tag="form">
    <b-row>
      <b-col cols="12" xl="4">
        <validation-provider #default="{ errors }" :name="$t('payment_link.title')" rules="required">
          <b-form-group :label="$t('payment_link.title')" label-for="payment-link-title">
            <b-form-input id="payment-link-title" v-model="paymentLink.title" trim :class="{ 'is-invalid': errors.length > 0 }" />
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col cols="12" md="6" lg="3" xl="2">
        <b-form-group :label="$t('payment_link.amount')" label-for="payment-link-amount">
          <validation-provider #default="{ errors }" :name="$t('payment_link.amount')" rules="min_value:1|max_value:999999.99">
            <b-input-group :class="{ 'is-invalid': errors.length > 0 }">
              <cleave v-model="paymentLink.amount" :options="amountOptions" class="form-control" id="payment-link-amount" :class="{ 'is-invalid': errors.length > 0 }" />
              <b-input-group-append is-text> ₺ </b-input-group-append>
            </b-input-group>
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="3" xl="2">
        <validation-provider #default="{ errors }" :name="$t('payment_link.create_date')" rules="required">
          <b-form-group :label="$t('payment_link.create_date')" label-for="payment-link-create-date">
            <flat-pickr id="payment-link-create-date" v-model="paymentLink.create_date" class="form-control text-muted" :config="pickConfig" disabled />
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col cols="12" md="6" lg="3" xl="2">
        <validation-provider #default="{ errors }" :name="$t('payment_link.expired_date')" rules="required">
          <b-form-group :label="$t('payment_link.expired_date')" label-for="payment-link-expired-date">
            <flat-pickr id="payment-link-expired-date" v-model="paymentLink.expired_date" class="form-control" :config="expiredDateConfig" />
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col cols="12" md="6" lg="3" xl="2">
        <b-form-group :label="$t('payment_link.limit')" label-for="payment-link-limit">
          <validation-provider #default="{ errors }" :name="$t('payment_link.limit')" rules="required|max_value:1000">
            <cleave v-model="paymentLink.limit" :options="amountOptions" class="form-control" id="payment-link-limit" :class="{ 'is-invalid': errors.length > 0 }" />
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-form-group :label="$t('payment_link.description')" label-for="payment-link-description">
          <validation-provider #default="{ errors }" :name="$t('payment_link.description')" rules="max:255">
            <b-form-textarea id="payment-link-description" v-model="paymentLink.description" :state="errors.length > 0 ? false : null" rows="2" no-resize />
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="d-flex justify-content-end align-items-center">
        <div @mouseenter="validate()">
          <b-button variant="primary" :disabled="invalid" @click="generatePaymentLink()">
            {{ $t("payment_link.generate") }}
          </b-button>
        </div>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BInputGroup, BInputGroupAppend, BButton } from "bootstrap-vue";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import { english } from "flatpickr/dist/l10n/default.js";
import Cleave from "vue-cleave-component";
import "cleave.js/dist/addons/cleave-phone.tr.js";
import { ValidationProvider, ValidationObserver } from "vee-validate/dist/vee-validate.full";
import { mapGetters } from "vuex";
import { DEFAULT_PAYMENT_LINK, GENERATE_PAYMENT_LINK, GET_ALL_PAYMENT_LINKS, MUTATE_ALL_PAYMENT_LINKS } from "@/store/services/paymentLink-service";

export default {
  name: "generate-payment-link",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupAppend,
    BButton,

    flatPickr,
    Cleave,
    ValidationProvider,
    ValidationObserver,
  },
  created() {
    this.$store.commit(DEFAULT_PAYMENT_LINK);
  },
  computed: {
    ...mapGetters(["paymentLink", "getLocale"]),
    amountOptions() {
      switch (this.getLocale) {
        case "tr-TR":
          return {
            numeral: true,
            numeralPositiveOnly: true,
            numeralDecimalScale: 2,
            numeralDecimalMark: ",",
            delimiter: ".",
          };
        default:
          return {
            numeral: true,
            numeralPositiveOnly: true,
            numeralDecimalScale: 2,
            numeralDecimalMark: ".",
            delimiter: ",",
          };
      }
    },
    pickConfig() {
      switch (this.getLocale) {
        case "tr-TR":
          return {
            mode: "single",
            dateFormat: "Z",
            altInput: true,
            altFormat: "d.m.Y H:i",
            locale: { ...Turkish, rangeSeparator: " - " },
          };
        default:
          return {
            mode: "single",
            dateFormat: "Z",
            altInput: true,
            altFormat: "m/d/Y H:i",
            locale: { ...english, rangeSeparator: " - " },
          };
      }
    },
    expiredDateConfig() {
      return {
        ...this.pickConfig,
        enableTime: true,
        defaultHour: 23,
        defaultMinute: 59,
        minuteIncrement: 30,
        minDate: moment()
          .add(1, "days")
          .startOf("day")
          .toDate(),
        maxDate: moment()
          .add(31, "days")
          .endOf("day")
          .toDate(),
      };
    },
  },
  methods: {
    generatePaymentLink() {
      this.$store.dispatch(GENERATE_PAYMENT_LINK).then(() => {
        this.$store.commit(MUTATE_ALL_PAYMENT_LINKS, undefined);
        this.$store.dispatch(GET_ALL_PAYMENT_LINKS);
      });
    },
    showDate(date) {
      return new Intl.DateTimeFormat(this.getLocale, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      }).format(new Date(date));
    },
    validate() {
      this.$refs.paymentLinkValidation.validate();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
