<template>
  <section class="basic-textarea">
    <b-row>
      <b-col cols="12" v-if="$can('manage', '/Payment/CreatePaymentLink')">
        <b-card>
          <b-card-header>
            <h4>{{ $t("payment_link.generate_link") }}</h4>
          </b-card-header>
          <b-card-body>
            <generate-payment-link />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card>
          <b-card-header>
            <h4>{{ $t("payment_link.links") }}</h4>
          </b-card-header>
          <b-card-body>
            <payment-links />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BCard, BCardHeader, BCardBody } from "bootstrap-vue";
import GeneratePaymentLink from "./GeneratePaymentLink.vue";
import PaymentLinks from "./Links.vue";

export default {
  name: "payment-links-index",
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,

    GeneratePaymentLink,
    PaymentLinks,
  },
};
</script>
